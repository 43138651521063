import Section from 'src/components/sections/Section'
import styled, { css } from 'styled-components'

import { containerCenter } from '../utils/containerCenter'
import { mediaMax } from '../utils/mediaMax'

export const Title = styled.h2`
  font-size: 35px;
  position: relative;
  text-align: center;
  font-family: 'Baloo';

  strong {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.orange_0};
  }
`

export const Subtitle = styled.p`
  text-align: center;
  margin-bottom: 16px;
  margin-top: 10px;
`

export const Mosaic = styled(Section)`
  display: flex;
  column-gap: 13px;
  flex-direction: row;

  ${mediaMax(
    css`
      flex-direction: column;
    `,
    767
  )}
`

export const MosaicItem = styled.div<{ full?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  &:not(:last-child) {
    ${mediaMax(
      css`
        margin-bottom: 13px;
      `,
      767
    )}
  }

  /* ${({ full }) =>
    full &&
    css`
    figure img {
      he
    }
  `} */

  img {
    transition: filter 0.5s ease-in-out;
    &:hover {
      filter: grayscale(100%);
    }
  }
`

export const BottomWrapper = styled.div`
  ${containerCenter}
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 1024px;
`

export const Paragraph = styled.p``

export const List = styled.ul`
  list-style: unset;
  margin-bottom: 20px;
  margin-top: 10px;
  padding-left: 16px;
`
