import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import { mark } from 'src/sdk/tests/mark'
import type {
  InstitutionalPageQueryQuery,
  InstitutionalPageQueryQueryVariables,
} from '@generated/graphql'
import Newsletter from 'src/components/sections/Newsletter'
import { ProductShelf } from 'src/components/sections/ProductShelf'
import ResponsiveImage from 'src/components/ui/Image/ResponsiveImage'
import Card from 'src/components/common/Institutional/Card'

import * as S from '../styles/pages/BlackFriday'

const Page: FCC<
  PageProps<InstitutionalPageQueryQuery, InstitutionalPageQueryQueryVariables>
> = (props) => {
  const {
    data: { site },
    location: { host, pathname },
  } = props

  const title = 'Black Friday'

  const canonical =
    host !== undefined
      ? `https://${host}${pathname.replace(/\/$/, '')}`
      : pathname.replace(/\/$/g, '')

  const cmsInfo = [
    {
      title: '<h3>Quando acontece a Black Friday?</h3>',
      content: `<p>Em 2022, nossa Black Friday acontecerá no dia 25 de Novembro. Mas, pra você não ficar na ansiedade, teremos várias surpresas durante o mês todo pra dar um gostinho do que vem por aí</p>
      <p>Salve esta data especial no seu calendário e não esqueça de ficar de olho em nossas redes sociais!</p>`,
    },

    {
      title:
        '<h3>Como fico por dentro das novidades da Black Friday Sex shop e Lingerie Miess?</h3>',
      content: `<strong>Assinando nossa newsletter</strong> e nos seguindo nas redes sociais!
      <ul>
         <li><a href="https://www.instagram.com/miessmodaintima/">• Instagram</a></li>
         <li><a href="https://www.facebook.com/miessloja">• Facebook</a></li>
         <li><a href="https://twitter.com/miessmodaintima">• Twitter</a></li>
         <li><a href="https://www.youtube.com/channel/UCSrRELkt-IX8vw9CyYcr4jw/videos">• Youtube</a></li>
         <li><a href="https://www.tiktok.com/@miessmodaintima">• Tik Tok</a></li>
         <li><a href="https://blog.miess.com.br/">• Blog</a></li>
      </ul><br>
                  <p>Além de atualizações sobre as melhores vantagens, temos conteúdos exclusivos sobre todo o universo de Sex Shop esperando por você!</p>`,
    },

    {
      title: '<h3>Quais são os canais de atendimento da Miess?</h3>',
      content: `<p>Você pode tirar suas dúvidas sobre produtos e preços pelo telefone (11) 4810-6810. Para solicitações referentes a pedidos, entre em contato e-mail sac@miess.com.br.</p>
      <p>Nosso horário de atendimento é de Segunda a Sexta das 8h às 18hs e Sábados das 8h às 12h.</p>`,
    },

    {
      title: '<h3>A Miess é confiável?</h3>',
      content: `<p>A Miess está presente no mercado há 14 anos, atuando como grande distribuidor de produtos eróticos e lingeries para clientes e revendedores de todo o Brasil. Por se tratar de uma marca conceituada, a loja conta com o reconhecimento do padrão de atendimento ao cliente atribuído pelo Reclame Aqui, e sendo também uma das empresas indicadas às premiações anuais.</p>
      <p>Por meio da loja online, é possível acompanhar o seu pedido em todos os momentos após a finalização da compra, e além disso, o site também conta com o selo de segurança Let's Encrypt, certificando a segurança da informação em todo o processo de navegação.</p>`,
    },

    {
      title: '<h3>Aproveite a Black Friday para se tornar um revendedor!</h3>',
      content: `<p>Já imaginou ter uma renda extra que te ajude a conquistar a independência financeira? Com a Miess você consegue se tornar um revendedor(a) e comercializar muitos produtinhos com ajuda do nosso catálogo! Fique de olho nas promoções e se inscreva para fazer parte!</p>
      <p>Baixe seu catálogo digital! <a href="https://www.miess.com.br/revenda-catalogo">clique aqui</a></p>`,
    },
  ]

  return (
    <>
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={
          site?.siteMetadata?.titleTemplate &&
          title.endsWith(site.siteMetadata.titleTemplate.replace('%s', ''))
            ? '%s'
            : site?.siteMetadata?.titleTemplate ?? ''
        }
        description={site?.siteMetadata?.description ?? ''}
        canonical={canonical}
        language="pt-br"
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            item: '/black-friday',
            name: 'Black Friday',
            position: 1,
          },
        ]}
      />

      {/*
        Sections: Components imported from '../components/sections' only.
        Do not import or render components from any other folder in here.
      */}

      <ResponsiveImage
        alt="Vem pra Black Friday Miess"
        desktop={{
          src: 'https://miess.vtexassets.com/arquivos/banner_friday_1.jpg',
          width: 3543,
          height: 1283,
        }}
        mobile={{
          src: 'https://miess.vtexassets.com/arquivos/banner_friday-mobile2_1.jpg',
          width: 380,
          height: 180,
        }}
        style={{
          marginBottom: '40px',
        }}
      />

      <ProductShelf
        clusterId={154}
        shelfTitle={
          <>
            <span>Não espere</span> a Black Friday para fazer amor
          </>
        }
      />

      <S.Title>
        Principais destaques da <strong>Black Miess</strong>
      </S.Title>

      <S.Subtitle>
        Ainda não se convenceu que a melhor
        <b>Black Friday de produtos eróticos e Lingerie está na Miess</b>? Dá
        uma olhadinha só no que está por vir.
      </S.Subtitle>

      <S.Mosaic>
        <S.MosaicItem>
          <ResponsiveImage
            href="/gel"
            alt="Mosaico 1"
            desktop={{
              src: 'https://miess.vtexassets.com/arquivos/categorias1_1.jpg?v=637732056893530000',
              width: 562,
              height: 327,
            }}
          />
          <ResponsiveImage
            href="/pimenta-sexy/?sort=release_desc&page=0"
            alt="Mosaico 2"
            desktop={{
              src: 'https://miess.vtexassets.com/arquivos/categorias2_1.jpg?v=637732056893530000',
              width: 562,
              height: 327,
            }}
          />
        </S.MosaicItem>
        <S.MosaicItem>
          <ResponsiveImage
            href="/Miess box"
            alt="Mosaico 3"
            desktop={{
              src: 'https://miess.vtexassets.com/arquivos/ids/244856/miessbox_1.jpg?v=637732059693800000',
              width: 564,
              height: 675,
            }}
          />
        </S.MosaicItem>
        <S.MosaicItem>
          <ResponsiveImage
            href="/kit géis"
            alt="Mosaico 4"
            desktop={{
              src: 'https://miess.vtexassets.com/arquivos/ids/244857/categorias3_1.jpg?v=637732056893530000',
              width: 562,
              height: 327,
            }}
          />
          <ResponsiveImage
            href="/conjunto neon"
            alt="Mosaico 5"
            desktop={{
              src: 'https://miess.vtexassets.com/arquivos/ids/244858/categorias4_1.jpg?v=637732056893530000',
              width: 562,
              height: 327,
            }}
          />
        </S.MosaicItem>
      </S.Mosaic>

      <S.BottomWrapper>
        <S.Title>
          Confira o que vai rolar na <strong>Black Friday 2022!</strong>
        </S.Title>

        <S.Subtitle>
          <b>Está curioso para saber o que você perdeu?</b>
        </S.Subtitle>

        <S.Paragraph>Acompanhe:</S.Paragraph>

        <S.List>
          <li>
            Os produtos mais queridos do mercado erótico com o melhor preço do
            Brasil!
          </li>
          <li>Brindes incríveis em todos os pedidos.</li>
          <li>Condições especiais de entrega para todo o Brasil.</li>
          <li>Mimos Surpresas.</li>
          <li>
            E não poderíamos esperar um resultado maravilhoso, foram 3.600
            pedidos finalizados somente no dia 27/11 na Black Friday de 2021!
          </li>
          <li>
            <strong>Não deixe de conferir a Black Miess de 2021!</strong>
          </li>
        </S.List>

        <div>
          {cmsInfo.map(({ title: cardTitle, content }, index) => (
            <Card key={index} title={cardTitle} content={content} />
          ))}
        </div>
      </S.BottomWrapper>

      <Newsletter />
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const query = graphql`
  query BlackFridayPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`
Page.displayName = 'Page'

export default mark(Page)
